import { OrgFeature } from '../../enums';
import { AbstractModel } from '../abstract.model';

export interface IOrg {
  name: string;
  slug: string;
  venueIds: string[] | Record<string, any>[];
  customerAccountScope: string;
  enabledFeatures: OrgFeature[];
  usesBlindCashDrops: boolean;
  timezone?: string;
}

export class Org extends AbstractModel<IOrg> {
  constructor(public data: IOrg) {
    super(data);
  }

  public hasFeature(feature: OrgFeature): boolean {
    return this.data.enabledFeatures.includes(feature);
  }
}
