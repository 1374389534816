import moment from 'moment-timezone';

export class TimeHelper {
  static readonly HOUR = 3600000;
  static readonly MINUTE = 60000;
  static readonly DEFAULT_TIMEZONE = 'America/New_York';

  static getDateInFormatWithTimezone(
    date: Date,
    format: string,
    timezone: string = this.DEFAULT_TIMEZONE
  ): string {
    return moment(date)
      .tz(timezone || this.DEFAULT_TIMEZONE)
      .format(format);
  }

  static isWithinTimeFrame(
    startTimeString: string,
    endTimeString: string,
    timezone: string = this.DEFAULT_TIMEZONE
  ): boolean {
    const now = this.getDateInFormatWithTimezone(new Date(), 'hh:mm', timezone);
    let withinTimeFrame = true;

    if (startTimeString && now < startTimeString) {
      withinTimeFrame = false;
    }

    if (endTimeString && now > endTimeString) {
      withinTimeFrame = false;
    }

    return withinTimeFrame;
  }
}
