/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-async-promise-executor */
import { Injectable } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { StarPrinterService } from '../star-micronics/star-printer.service';
import {
  PrintCommands,
  ReceiptGeneratorService,
  ReceiptOptions,
} from './receipt-generator.service';
import { PrinterRole } from '../../enums';
import { PosCashDrawer, PosPrinter, Printer, IShift, Venue } from '../../models';
import { OrderData } from '../../interfaces';
import { SelectPrinterModalComponent } from '../../shared/select-printer-modal/select-printer-modal.component';
import { selectPrinters, selectShift, selectVenue } from '../../state';

interface PrintOptions {
  printers: PosPrinter[]; // Array of printers setup
  order?: OrderData; // Object representing the order
  transaction?: any; // Charge object which is needed for receipts if post payment
  isEdit?: boolean; // Whether to only print non-saved (i.e. newly added) items
  shift?: any; // The shift to print
  summary?: any; // The summary for the shift
  reportData?: any; // The summary for the shift
  netTenderSummary?: Record<string, { amount: number; name: string }>[]; // The summary for the shift
  quantity?: number; // The number of chits to print
}

interface PrintJobOptions {
  port: string;
  emulation: string;
}

@Injectable({
  providedIn: 'root',
})
export class PrintService {
  public cloudReceiptPrinters: Printer[] = [];

  public cloudFulfillmentPrinters: Printer[] = [];

  private shift: IShift = null;

  private cloudPrinters$: Observable<Printer[]> = this.store.pipe(select(selectPrinters));

  private venue$: Observable<Venue> = this.store.pipe(select(selectVenue));

  private shift$: Observable<IShift> = this.store.pipe(select(selectShift));

  private receiptConfig: ReceiptOptions;

  constructor(
    private store: Store,
    private _toastCtrl: ToastController,
    private starPrinterService: StarPrinterService,
    private receiptGenerator: ReceiptGeneratorService,
    private modalCtrl: ModalController
  ) {
    this.watchCloudPrinters();
  }

  private watchCloudPrinters() {
    this.cloudPrinters$.subscribe(printers => {
      this.cloudReceiptPrinters = printers?.filter(p =>
        p.data.printerRole.includes(PrinterRole.RECEIPT)
      );
      this.cloudFulfillmentPrinters = printers?.filter(p =>
        p.data.printerRole.includes(PrinterRole.FULFILLMENT)
      );
    });
    this.venue$.subscribe(venue => {
      this.receiptConfig = venue?.data.guestReceiptConfig;
    });
    this.shift$.subscribe(shift => {
      this.shift = shift;
    });
  }

  public async managePrinting(types: PrinterRole[], options: PrintOptions) {
    try {
      const results = await this.printTypes(types, options);
      console.log('manage printing results');
      console.log(results);
    } catch (e) {
      console.log('manage printing error');
      console.log(e);
      const toast = await this._toastCtrl.create({
        message: e.message,
        color: 'warning',
        position: 'top',
        duration: 3000,
      });
      await toast.present();
    }
  }

  public async printTypes(types: PrinterRole[], options: PrintOptions): Promise<any> {
    const { quantity = 1 } = options;
    return new Promise(async (resolve, reject) => {
      try {
        const results = [];
        for await (const type of types) {
          switch (type) {
            case PrinterRole.RECEIPT: {
              const receiptResult = await this.receipt(
                options.printers,
                this.receiptConfig,
                options.order,
                options.transaction
              );
              for (let i = 0; i < quantity; i++) {
                results.push(receiptResult);
              }
              break;
            }
            case PrinterRole.FULFILLMENT: {
              const kitchenResult = await this.printKitchen(
                options.printers,
                options.order,
                options.isEdit
              );
              for (let i = 0; i < quantity; i++) {
                results.push(kitchenResult);
              }
              break;
            }
            case PrinterRole.SHIFT: {
              const shiftResult = await this.printShift(options);
              for (let i = 0; i < quantity; i++) {
                results.push(shiftResult);
              }
            }
          }
        }
        resolve(results);
      } catch (e) {
        reject(e);
      }
    });
  }

  private static getEmulationFromPrinterModel(model: string): string {
    switch (model) {
      case 'mPOP':
        return 'StarPRNT';
      case 'FVP10':
        return 'StarLine';
      case 'TSP100':
        return 'StarGraphic';
      case 'TSP650II':
        return 'StarLine';
      case 'TSP700II':
        return 'StarLine';
      case 'TSP800II':
        return 'StarLine';
      case 'SP700':
        return 'StarDotImpact';
      case 'SM-S210i':
        return 'EscPosMobile';
      case 'SM-S220i':
        return 'EscPosMobile';
      case 'SM-S230i':
        return 'EscPosMobile';
      case 'SM-T300i':
        return 'EscPosMobile';
      case 'SM-T300':
        return 'EscPosMobile';
      case 'SM-T400i':
        return 'EscPosMobile';
      case 'SM-L200':
        return 'StarPRNT';
      case 'SM-L300':
        return 'StarPRNT';
    }
    return 'StarPRNT';
  }

  public async testPrint(printer: PosPrinter) {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await this.print(
          {
            port: printer.port,
            emulation: PrintService.getEmulationFromPrinterModel(printer.model),
          },
          this.receiptGenerator.getTestReceipt(printer)
        );
        resolve(result);
      } catch (e) {
        reject(e);
      }
    });
  }

  public async receipt(
    printers: PosPrinter[],
    receiptConfig: ReceiptOptions,
    order: OrderData,
    transaction?: any
  ): Promise<any> {
    return new Promise(async (resolve, reject) => {
      console.log(this.shift);
      const receiptPrinter = printers.find(x => x.roles.indexOf(PrinterRole.RECEIPT) > -1);
      if (receiptPrinter) {
        const receipt = this.receiptGenerator.receipt(
          receiptPrinter,
          receiptConfig,
          order,
          this.shift?.openingBy,
          transaction
        );
        try {
          const result = await this.print(
            {
              port: receiptPrinter.port,
              emulation: PrintService.getEmulationFromPrinterModel(receiptPrinter.model),
            },
            receipt
          );
          resolve(result);
        } catch (e) {
          reject(e);
        }
      } else {
        resolve({ message: 'No receipt printers setup' });
      }
    });
  }

  public async printKitchen(
    printers: PosPrinter[],
    order: OrderData,
    isEdit?: boolean
  ): Promise<any> {
    return new Promise(async resolve => {
      const kitchenPrinters = printers.filter(x => x.roles.indexOf(PrinterRole.FULFILLMENT) > -1);
      const jobs: {
        printer: PosPrinter;
        station?: string;
        commands: PrintCommands;
      }[] = [];
      kitchenPrinters.forEach(printer => {
        if (printer.stations && printer.stations.length) {
          printer.stations.forEach(station => {
            const commands = this.receiptGenerator.kitchenReceipt(
              printer,
              order,
              station,
              isEdit,
              this.shift?.openingBy
            );
            if (!commands.length) {
              return;
            }
            jobs.push({
              printer,
              station,
              commands,
            });
          });
        } else {
          const commands = this.receiptGenerator.kitchenReceipt(
            printer,
            order,
            null,
            isEdit,
            this.shift?.openingBy
          );
          if (!commands.length) {
            return;
          }
          jobs.push({
            printer,
            commands,
          });
        }
      });
      if (jobs.length) {
        const results = [];
        for await (const printJob of jobs) {
          const printJobResult = await this.print(
            {
              port: printJob.printer.port,
              emulation: PrintService.getEmulationFromPrinterModel(printJob.printer.model),
            },
            printJob.commands
          );
          results.push(printJobResult);
        }
        resolve(results);
      } else {
        resolve({ message: 'No fulfillment printers setup' });
      }
    });
  }

  public async printShift(options: PrintOptions): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const { printers, shift, reportData, netTenderSummary } = options;
      const shiftPrinter = printers.find(printer => printer.roles.includes(PrinterRole.SHIFT));
      if (shiftPrinter) {
        try {
          const commands = this.receiptGenerator.shiftReport(shiftPrinter, shift, {
            ...reportData,
            netTenderSummary,
          });
          const result = await this.print(
            {
              port: shiftPrinter.port,
              emulation: PrintService.getEmulationFromPrinterModel(shiftPrinter.model),
            },
            commands
          );
          resolve(result);
        } catch (e) {
          reject(e);
        }
      } else {
        reject({ message: 'No shift printer is setup' });
      }
    });
  }

  private async print(options: PrintJobOptions, commands: PrintCommands): Promise<any> {
    return new Promise(async (resolve, reject) => {
      if (commands.length) {
        try {
          console.log('commands');
          console.log(commands);
          const result = await this.starPrinterService.print(commands, options);
          await this.starPrinterService.disconnect();
          resolve(result);
        } catch (e) {
          reject(e);
        }
      } else {
        resolve({ message: 'No receipt to be printed' });
      }
    });
  }

  public async openCashDrawer(cashDrawer: PosCashDrawer): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await this.starPrinterService.openCashDrawer({
          port: cashDrawer.port,
          emulation: '',
        });
        await this.starPrinterService.disconnect();
        resolve(result);
      } catch (e) {
        reject(e);
      }
    });
  }

  public async isConnected(printer: PosPrinter): Promise<boolean> {
    return this.starPrinterService.isConnected({
      port: printer.port,
      emulation: PrintService.getEmulationFromPrinterModel(printer.model),
    });
  }

  public async getCloudPrinterId(type: PrinterRole): Promise<string | null> {
    const cloudPrinters =
      type === PrinterRole.RECEIPT ? this.cloudReceiptPrinters : this.cloudFulfillmentPrinters;
    if (!cloudPrinters.length) {
      return null;
    }
    if (cloudPrinters.length === 1) {
      return cloudPrinters[0].id;
    }
    const modal = await this.modalCtrl.create({
      component: SelectPrinterModalComponent,
      componentProps: {
        title: 'Select Printer',
        printers: cloudPrinters,
      },
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data?.printer) {
      return data.printer.id;
    }
    return null;
  }
}
