import _ from 'lodash';
import {
  DiningOptionsBehavior,
  FulfillmentState,
  OrderStatus,
  TransactionCardScheme,
  TransactionGateway,
  TransactionResult,
  TransactionState,
  TransactionType,
} from '../../enums';
import {
  OrderData,
  OrderedItem,
  OrderedItemModifier,
  OrderStatusAction,
  WithOrderedItem,
} from '../../interfaces';
import { AbstractModel } from '../abstract.model';
import { DiningOption, MenuItem } from '../concession';

export class Order extends AbstractModel<OrderData> {
  static readonly keysToClean = [
    'item',
    'restaurant',
    'shift',
    'employee',
    'modifiers',
    'taxes',
    'id',
  ];

  static readonly openOrderStatuses: OrderStatus[] = [
    OrderStatus.PENDING,
    OrderStatus.ACCEPTED,
    OrderStatus.READY_FOR_PICKUP,
    OrderStatus.OUT_FOR_DELIVERY,
    OrderStatus.POINT_OF_SALE,
  ];

  private orderedItemsById: Record<string, any>;

  private transactions: any[];

  public itemsToFulfillCount = 0;

  public class: string;

  public messages: {
    _id: string;
    count: number;
    unread: number;
  };

  constructor(public data: OrderData) {
    super(data);
    this.messages = {
      _id: data._id,
      count: 0,
      unread: 0,
    };
    if (_.size(this.data?.items)) {
      this.orderedItemsById = _.keyBy(
        this.data.items.map(item => {
          return new MenuItem(item.item as any);
        }),
        'id'
      );
      this.setItemNames();
      this.data.items = Order.stripDownToIds(this.data.items);
      this.setFulfillCount();
    }
    this.transactions = data.transactions || [];
  }

  get itemCount() {
    return _.size(this.data.items);
  }

  get taxesAndFees() {
    const taxes = this.get('taxTotal') || 0;
    const fees = this.get('serviceFee') || 0;
    const roundUp = this.get('roundUpTotal') || 0;
    return taxes + fees + roundUp;
  }

  get due() {
    return this.get('due');
  }

  get total() {
    return this.getTotal();
  }

  get canSave() {
    const orderBehavior = this.data.orderDestination?.behaviour;
    const isSavableDiningOption = [
      DiningOptionsBehavior.TAB,
      DiningOptionsBehavior.TABLE,
      DiningOptionsBehavior.EVENT,
    ].includes(orderBehavior);
    return !!(isSavableDiningOption || this.data.member);
  }

  get isTab() {
    const orderBehavior = this.data.orderDestination?.behaviour;
    return orderBehavior === DiningOptionsBehavior.TAB;
  }

  get isTable() {
    const orderBehavior = this.data.orderDestination?.behaviour;
    return orderBehavior === DiningOptionsBehavior.TABLE;
  }

  get isEvent() {
    const orderBehavior = this.data.orderDestination?.behaviour;
    return orderBehavior === DiningOptionsBehavior.EVENT;
  }

  get classNames() {
    return `${this.data.orderDestination?.behaviour?.toLowerCase()} ${this.data.status}`;
  }

  get canSend() {
    const orderBehavior = this.data.orderDestination?.behaviour;
    return [DiningOptionsBehavior.DELIVERY, DiningOptionsBehavior.PICKUP].includes(orderBehavior);
  }

  get canTransfer() {
    const orderBehavior = this.data.orderDestination?.behaviour;
    return [DiningOptionsBehavior.TAB, DiningOptionsBehavior.TABLE].includes(orderBehavior);
  }

  get canMerge() {
    return !this.data.paid && !this.data.isConsumerOrder;
  }

  get hasCardOnFile() {
    return this.data.cardOnFile || this.data.guestCardsOnFile?.length;
  }

  get isMissingPayment() {
    const hasMember = this.data.member;
    if (hasMember) {
      return false;
    }
    if (this.data.orderDestination?.behaviour === 'TABLE') {
      return false;
    }
    return !this.data.paid && !this.hasCardOnFile;
  }

  private setItemNames() {
    this.data.items.forEach((item: OrderedItem) => {
      if (!item.name) {
        item.name = _.get(item, 'item.name');
      }
    });
  }

  private setFulfillCount() {
    this.itemsToFulfillCount =
      this.getItemsToFulfillIds().length + this.getModifiersToFulfillIds().length;
  }

  public getMemberId(): string | undefined {
    if (this.data.member) {
      return (this.data.member as any)._id || this.data.member;
    }
  }

  public getTableName(): string {
    if (this.data.orderDestination.behaviour === DiningOptionsBehavior.TABLE) {
      const parts = [];
      if (this.data.orderDestination.location.option4) {
        parts.push(this.data.orderDestination.location.option2);
        parts.push(this.data.orderDestination.location.option4);
      } else {
        parts.push(this.data.orderDestination.location.option1);
        if (this.data.orderDestination.location.option2) {
          parts.push(this.data.orderDestination.location.option2);
        }
      }
      return parts.join(' - ');
    }
  }

  public getTransactionsForReceipts(): any[] {
    return this.data.transactions.filter(transaction => {
      if (transaction.cardScheme === TransactionCardScheme.MEMBER) {
        return true;
      }
      return (
        transaction.gateway === TransactionGateway.STRIPE &&
        transaction.type === TransactionType.SALE &&
        transaction.state === TransactionState.AUTHORIZED &&
        transaction.result === TransactionResult.APPROVED &&
        !transaction.gratuity
      );
    });
  }

  public getTransferToDiningOptions(diningOptions: ReadonlyArray<DiningOption>): DiningOption[] {
    if (this.data.isConsumerOrder) {
      return [];
    }
    return diningOptions.filter(diningOption => {
      if (diningOption.id === this.data.orderDestination.diningOptionId) {
        return false;
      }
      return [DiningOptionsBehavior.TAB, DiningOptionsBehavior.TABLE].includes(
        diningOption.data.behaviour
      );
    });
  }

  public getNextStatusAction(isKds = false): OrderStatusAction {
    const behavior = this.data.orderDestination.behaviour;
    switch (this.data.status) {
      case OrderStatus.PENDING:
        return {
          name: 'Accept',
          action: 'update-order-status',
          value: OrderStatus.ACCEPTED,
        };
      case OrderStatus.ACCEPTED: {
        if (behavior === DiningOptionsBehavior.DELIVERY) {
          return {
            name: 'Out for Delivery',
            action: 'update-order-status',
            value: OrderStatus.OUT_FOR_DELIVERY,
          };
        }
        return {
          name: 'Ready for Pickup',
          action: 'update-order-status',
          value: OrderStatus.READY_FOR_PICKUP,
        };
      }
      case OrderStatus.READY_FOR_PICKUP:
        return {
          name: 'Picked Up',
          action: 'update-order-status',
          value: OrderStatus.DELIVERED,
        };
      case OrderStatus.OUT_FOR_DELIVERY:
        return {
          name: 'Picked Up',
          action: 'update-order-status',
          value: OrderStatus.DELIVERED,
        };
      case OrderStatus.POINT_OF_SALE: {
        if (isKds) {
          if (this.getItemsToFulfillIds().length || this.getModifiersToFulfillIds().length) {
            return {
              name: 'Items Ready',
              action: 'update-item-status',
            };
          }
          return null;
        }
        return {
          name: 'Checkout',
          action: 'checkout',
        };
      }
      case OrderStatus.DELIVERED: {
        if (isKds) {
          if (this.getItemsToFulfillIds().length || this.getModifiersToFulfillIds().length) {
            return {
              name: 'Items Ready',
              action: 'update-item-status',
            };
          }
          return {
            name: 'Close Order',
            action: 'close-order',
            value: FulfillmentState.FULFILLED,
          };
        }
      }
    }
  }

  public supportsFulfillmentOnEdit(): boolean {
    const behavior = this.data.orderDestination.behaviour;
    if ([DiningOptionsBehavior.TAB, DiningOptionsBehavior.TABLE].includes(behavior)) {
      return true;
    }
    return false;
  }

  public getItemsToFulfillIds(): string[] {
    const ids = [];
    this.data.items.forEach((item: OrderedItem) => {
      if (item.isSaved && !item.isReady) {
        ids.push(item._id);
      }
    });
    return ids;
  }

  public getModifiersToFulfillIds(): string[] {
    const ids = [];
    this.data.items.forEach((item: OrderedItem) => {
      if (item.isSaved) {
        item.modifiers.forEach(modifier => {
          if (!modifier.isReady) {
            ids.push(modifier._id);
          }
        });
      }
    });
    return ids;
  }

  public setAllItemsReady(): void {
    this.data.items.forEach((item: OrderedItem) => {
      item.isReady = true;
      item.modifiers.forEach(modifier => {
        modifier.isReady = true;
      });
    });
    this.setFulfillCount();
  }

  public setAllStationItemsReady(stationId: string): void {
    this.data.items.forEach((item: OrderedItem) => {
      if (item.stations.includes(stationId)) {
        item.isReady = true;
      }
      item.modifiers.forEach(modifier => {
        if (modifier.stations.includes(stationId)) {
          modifier.isReady = true;
        }
      });
    });
    this.setFulfillCount();
  }

  public setNewItemsToReady(): void {
    this.data.items.forEach((item: OrderedItem) => {
      if (!item.isSaved) {
        item.isReady = true;
        item.modifiers.forEach(modifier => {
          modifier.isReady = true;
        });
      }
    });
    this.setFulfillCount();
  }

  public setNonStationItemsToReady(): void {
    this.data.items.forEach((item: OrderedItem) => {
      if (!item.isSaved && !item.stations?.length) {
        item.isReady = true;
      }
      item.modifiers.forEach(modifier => {
        if (!item.isSaved && !modifier.stations?.length) {
          modifier.isReady = true;
        }
      });
    });
    this.setFulfillCount();
  }

  public setItemToReady(itemId: string): void {
    const orderedItem = this.data.items.find((item: OrderedItem) => {
      return item._id === itemId;
    });
    if (orderedItem) {
      orderedItem.isReady = true;
      this.setFulfillCount();
    }
  }

  public setModifierToReady(itemId: string, modifierId: string): void {
    const orderedItem = this.data.items.find((item: OrderedItem) => {
      return item._id === itemId;
    });
    if (orderedItem) {
      const orderedItemModifier = orderedItem.modifiers.find(modifier => {
        return modifier._id === modifierId;
      });
      if (orderedItemModifier) {
        orderedItemModifier.isReady = true;
        this.setFulfillCount();
      }
    }
  }

  public static stripDownToIds<T>(items: Array<any>): Array<T> {
    return items.map((item: any) => {
      const copy = _.cloneDeep(item);
      _.each(Order.keysToClean, (key: string) => {
        const propertyValue = _.get(copy, key);
        if (_.isObject(propertyValue)) {
          copy[key] = _.get(propertyValue, '_id');
        }
        if (_.isArray(propertyValue)) {
          copy[key] = Order.stripDownToIds(propertyValue);
        }
      });
      return {
        ...copy,
      } as T;
    });
  }

  public getOrderedItemsById() {
    return this.orderedItemsById;
  }

  public getKdsItemsForLocationId(locationId: string, showFulfilledItems: boolean) {
    const items = this.data.items.filter((item: OrderedItem) => {
      if (!item.isSaved) {
        return;
      }
      if (item.restaurant !== locationId) {
        return false;
      }
      if (!item.isReady || showFulfilledItems) {
        return true;
      }
      return false;
    });
    return items;
  }

  /**
   * When we use stations we flatten modifiers which belong to this station
   */
  public getKdsItemsForStationId(stationId: string, showFulfilledItems: boolean) {
    const items: (OrderedItem | WithOrderedItem<OrderedItemModifier>)[] = [];
    this.data.items.forEach((item: OrderedItem) => {
      if (!item.isSaved) {
        return;
      }
      if (item.stations.includes(stationId) && !item.isReady) {
        items.push(item);
      }
      if (item.stations.includes(stationId) && item.isReady && showFulfilledItems) {
        items.push(item);
      }
      item.modifiers.forEach(modifier => {
        if (modifier.stations.includes(stationId) && !modifier.isReady) {
          items.push({
            ...modifier,
            orderedItem: item,
          });
        }
        if (modifier.stations.includes(stationId) && modifier.isReady && showFulfilledItems) {
          items.push({
            ...modifier,
            orderedItem: item,
          });
        }
      });
    });
    return items;
  }

  public getKdsItemsForLocationAndStationId(
    locationId: string,
    stationId: string,
    showFulfilledItems: boolean
  ) {
    // First handle items which belong to this location
    // Then check if they have the correct location
    const items: (OrderedItem | WithOrderedItem<OrderedItemModifier>)[] =
      this.getKdsItemsForStationId(stationId, showFulfilledItems);
    return items.filter((item: OrderedItem) => {
      if (!item.isSaved) {
        return;
      }
      if (item.restaurant !== locationId) {
        return false;
      }
      if (!item.isReady || showFulfilledItems) {
        return true;
      }
      return false;
    });
  }

  private setSubTotal() {
    const subTotal = this.data.items.reduce((total, item) => {
      const modifierPrice = item.modifiers.reduce((modTotal, modifier) => {
        return modTotal + modifier.unitPrice * modifier.quantity * item.quantity;
      }, 0);
      return total + item.unitPrice * item.quantity + modifierPrice;
    }, 0);
    this.data.subTotal = subTotal;
  }

  private setDiscountTotal() {
    const discountTotal = this.data.items.reduce((total, item) => {
      if (item.discountTotal) {
        return total + item.discountTotal;
      }
      return total;
    }, 0);
    this.data.discount = discountTotal;
  }

  private getTaxIncludedTaxTotal() {
    return this.data.items.reduce((total, item) => {
      const itemTaxIncludedTotal = item.taxIncluded ? item.itemTaxTotal : 0;
      const modifierTaxIncludedTotal = item.modifiers.reduce((modTotal, modifier) => {
        return modTotal + (modifier.taxIncluded ? modifier.taxTotal : 0);
      }, 0);
      return total + itemTaxIncludedTotal + modifierTaxIncludedTotal;
    }, 0);
  }

  private setTaxTotal() {
    const taxTotal = this.data.items.reduce((total, item) => {
      const itemTaxTotal = item.taxIncluded ? 0 : item.itemTaxTotal;
      const modifierTaxTotal = item.modifiers.reduce((modTotal, modifier) => {
        return modTotal + (modifier.taxIncluded ? 0 : modifier.taxTotal);
      }, 0);
      return total + itemTaxTotal + modifierTaxTotal;
    }, 0);
    this.data.taxTotal = taxTotal;
    this.data.taxIncludedTaxTotal = this.getTaxIncludedTaxTotal();
    this.data.venueTax = taxTotal; // TODO - REMOVE
  }

  private setServiceFee() {
    if (!this.data.items.length) {
      this.data.serviceFee = 0;
      this.data.partakeServiceFee = 0;
      return;
    }
    const serviceChargeTotal = this.data?.serviceCharges.reduce((total, serviceCharge) => {
      return total + serviceCharge.amount;
    }, 0);
    this.data.serviceFee = serviceChargeTotal;
    const partakeServiceChargeTotal = this.data?.serviceCharges.reduce((total, serviceCharge) => {
      if (serviceCharge.isPartake) {
        return total + serviceCharge.amount;
      }
      return total;
    }, 0);
    this.data.partakeServiceFee = partakeServiceChargeTotal;
  }

  private setRoundUpTotal() {
    if (!this.data.shouldRoundUp) {
      this.data.roundUpTotal = 0;
      return;
    }
    const preRoundTotal =
      this.data.subTotal +
      this.data.serviceFee +
      this.data.taxTotal +
      this.data.gratuity -
      this.data.discount;
    const roundedUpTotal = Math.ceil(preRoundTotal * 0.01) * 100;
    this.data.roundUpTotal = roundedUpTotal - preRoundTotal;
  }

  private getTotal() {
    return (
      this.data.subTotal +
      this.data.serviceFee +
      this.data.taxTotal +
      this.data.roundUpTotal +
      this.data.gratuity -
      this.data.discount
    );
  }

  private setTotal() {
    const total = this.getTotal();
    this.data.total = total;
  }

  private setAmountPaid() {
    if (_.size(this.transactions)) {
      const amountPaid = this.transactions.reduce((paidTotal: number, transaction: any) => {
        if (transaction.state === 'captured') {
          if (transaction.type === 'sale') {
            return paidTotal + transaction.amount;
          }
          if (transaction.type === 'refund') {
            return paidTotal - transaction.amount;
          }
        }
        return paidTotal;
      }, 0);
      this.data.amountPaid = amountPaid;
    } else {
      this.data.amountPaid = 0;
    }
  }

  private setDue() {
    this.data.due = this.data.total - this.data.amountPaid;
  }

  public addToOrder(item: OrderedItem, editIndex = -1): void {
    if (editIndex > -1) {
      this.data.items[editIndex] = item;
    } else {
      this.data.items.push(item);
    }
    if (item.isAlcoholic) {
      this.set('alcoholCount', this.get('alcoholCount') + item.quantity);
    }
    this.updateCosts();
    this.setFulfillCount();
  }

  public removeFromOrder(idx: number): OrderedItem {
    const [removedOrderedItem]: OrderedItem[] = this.data.items.splice(idx, 1);
    if (removedOrderedItem.isAlcoholic) {
      this.set('alcoholCount', this.get('alcoholCount') - removedOrderedItem.quantity);
    }
    if (!removedOrderedItem.isSaved) {
      this.data.removed.push({
        ...removedOrderedItem,
        removedAt: new Date(),
      });
    }
    this.updateCosts();
    this.setFulfillCount();
    return removedOrderedItem;
  }

  public setItemsToSaved() {
    this.data.items.forEach(item => {
      item.isSaved = true;
    });
  }

  public updateCosts(): void {
    this.setSubTotal();
    this.setDiscountTotal();
    this.setTaxTotal();
    this.setServiceFee();
    this.setRoundUpTotal();
    this.setTotal();
    this.setAmountPaid();
    this.setDue();
  }

  get json() {
    return {
      ...this.data,
      deliveryOption: _.toLower(this.get('orderDestination.behaviour')),
    };
  }
}
