import { PriceOptionData } from '../models/concession/price-option';
import { TimeHelper } from './time.helper';

export class PriceOptionHelper {
  static getActivePriceOption(
    priceOption: PriceOptionData,
    dayOfWeek: string,
    timezone: string
  ): PriceOptionData {
    if (!priceOption.promoPricing?.length) {
      return priceOption;
    }

    const activePromoPrice = priceOption.promoPricing.find(promoPrice =>
      promoPrice.daysOfWeek.includes(dayOfWeek) && promoPrice.isActive
    );

    if (!activePromoPrice) {
      return priceOption;
    }

    const withinTimeFrame = TimeHelper.isWithinTimeFrame(
      activePromoPrice.startTimeString,
      activePromoPrice.endTimeString,
      timezone
    );

    if (!withinTimeFrame) {
      return priceOption;
    }

    return {
      ...priceOption,
      preDiscountPrice: priceOption.price,
      preTaxPrice: activePromoPrice.preTaxPrice,
      taxAmount: activePromoPrice.taxAmount,
      price: activePromoPrice.price,
      tag: activePromoPrice.tag
    };
  }
}
